import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Article = ({ excerpt, frontmatter }) => {
  const { title, image, slug, } = frontmatter;

  return (
    <div className="rounded-xl border-1 border-black bg-gray-100 flex flex-col">
      <GatsbyImage
        image={getImage(image)}
        alt={title}
        className="rounded-t-xl"
      />
      <div className="p-4 flex flex-wrap">
        <h3 className="nexaTextBold pb-3">{title}</h3>
        <p className="pb-4">{excerpt}</p>
      </div>
      <div className="flex flex-wrap mt-auto items-center pb-2 pl-4">
        <Link
          to={`/articulos/${slug}`}
          className="pink-button w-2/3 py-2"
        >
          Leer más
        </Link>
      </div>
    </div>
  );
};


export default Article;
